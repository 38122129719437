body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    overflow-wrap: break-word;
}

.app-container {
    padding-left: 350px;
    padding-right: 350px;
}

@media screen and (max-width: 1750px) {
    .app-container {
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media screen and (max-width: 1500px) {
    .app-container {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media screen and (max-width: 1250px) {
    .app-container {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width: 425px) {
    .app-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}
