.faq {
  padding-top: 200px;
  padding-bottom: 100px;
  @media screen and (max-width: 1200px) {
    padding-top: 60px;
  }
  &-title {
    font-size: 70px;
    font-family: "Helvetica Neue Medium";
    line-height: 120px;
    margin-bottom: 25px;
    @media screen and (max-width: 675px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media screen and (max-width: 482px) {
      font-size: 40px;
    }
    @media screen and (max-width: 325px) {
      font-size: 30px;
      line-height: 30px;
    }
  }
  &-subtitle {
    overflow-wrap: break-word;
    margin-top: 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-size: 25px;
    font-style: italic;
    font-weight: bold;
  }
  p,
  ul {
    margin: 0px;
    margin-bottom: 20px;
    font-family: "Sofia Pro Light";
    font-size: 18px;
    line-height: 25px;
  }
  ul.page-number {
    list-style-type: decimal;
  }
  ul.page-circle {
    list-style-type: circle;
  }
  ul.page-disc {
    list-style-type: disc;
    margin-bottom: 0px;
  }
  ul.page-none {
    list-style-type: none;
  }
  ul.page-alpha {
    list-style-type: lower-alpha;
  }
  ul.page-roman {
    list-style-type: lower-roman;
  }
}
