/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Helvetica Neue Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Italic'), url('HelveticaNeueItalic.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 35 Thin';
font-style: normal;
font-weight: normal;
src: local('Helvetica 35 Thin'), url('HelveticaNeue-Thin.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 37 Thin Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 37 Thin Condensed'), url('HelveticaNeue-ThinCond.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 37 Thin Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 37 Thin Condensed Oblique'), url('HelveticaNeue-ThinCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 33 Thin Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 33 Thin Extended Oblique'), url('HelveticaNeue-ThinExtObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 36 Thin Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica 36 Thin Italic'), url('HelveticaNeue-ThinItalic.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 43 Light Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 43 Light Extended'), url('HelveticaNeue-LightExt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 27 Ultra Light Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 27 Ultra Light Condensed'), url('HelveticaNeue-UltraLigCond.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 23 Ultra Light Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 23 Ultra Light Extended'), url('HelveticaNeue-UltraLigExt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 47 Light Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 47 Light Condensed Oblique'), url('HelveticaNeue-LightCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 43 Light Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 43 Light Extended Oblique'), url('HelveticaNeue-LightExtObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 27 Ultra Light Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 27 Ultra Light Condensed Oblique'), url('HelveticaNeue-UltraLigCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 83 Heavy Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 83 Heavy Extended'), url('HelveticaNeue-HeavyExt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 53 Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 53 Extended'), url('HelveticaNeue-Extended.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 85 Heavy';
font-style: normal;
font-weight: normal;
src: local('Helvetica 85 Heavy'), url('HelveticaNeue-Heavy.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 87 Heavy Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 87 Heavy Condensed'), url('HelveticaNeue-HeavyCond.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 107 Extra Black Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 107 Extra Black Condensed'), url('HelveticaNeue-ExtBlackCond.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 57 Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 57 Condensed'), url('HelveticaNeue-Condensed.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 63 Medium Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 63 Medium Extended'), url('HelveticaNeue-MediumExt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 55 Roman';
font-style: normal;
font-weight: normal;
src: local('Helvetica 55 Roman'), url('HelveticaNeue-Roman.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 83 Heavy Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 83 Heavy Extended Oblique'), url('HelveticaNeue-HeavyExtObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 87 Heavy Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 87 Heavy Condensed Oblique'), url('HelveticaNeue-HeavyCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 53 Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 53 Extended Oblique'), url('HelveticaNeue-ExtendedObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 107 Extra Black Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 107 Extra Black Condensed Oblique'), url('HelveticaNeue-ExtBlackCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 57 Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 57 Condensed Oblique'), url('HelveticaNeue-CondensedObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 86 Heavy Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica 86 Heavy Italic'), url('HelveticaNeue-HeavyItalic.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 63 Medium Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 63 Medium Extended Oblique'), url('HelveticaNeue-MediumExtObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 73 Bold Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 73 Bold Extended'), url('HelveticaNeue-BoldExt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 73 Bold Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 73 Bold Extended Oblique'), url('HelveticaNeue-BoldExtObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 77 Bold Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 77 Bold Condensed Oblique'), url('HelveticaNeue-BoldCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 93 Black Extended';
font-style: normal;
font-weight: normal;
src: local('Helvetica 93 Black Extended'), url('HelveticaNeue-BlackExt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 95 Black';
font-style: normal;
font-weight: normal;
src: local('Helvetica 95 Black'), url('HelveticaNeue-Black.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 93 Black Extended Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 93 Black Extended Oblique'), url('HelveticaNeue-BlackExtObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue UltraLight';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue UltraLight'), url('HelveticaNeueUltraLight.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue UltraLight Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue UltraLight Italic'), url('HelveticaNeueUltraLightItal.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 45 Light';
font-style: normal;
font-weight: normal;
src: local('Helvetica 45 Light'), url('HelveticaNeue-Light.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Light';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Light'), url('HelveticaNeueLight.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 47 Light Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 47 Light Condensed'), url('helvetica-47-light-condensed-587ebd7b5a6f6.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Light Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Light Italic'), url('HelveticaNeueLightItalic.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 46 Light Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica 46 Light Italic'), url('helvetica-46-light-italic-587ebdb0ea724.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Medium';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Medium'), url('HelveticaNeueMedium.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 67 Medium Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 67 Medium Condensed'), url('HelveticaNeue-MediumCond.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 65 Medium';
font-style: normal;
font-weight: normal;
src: local('Helvetica 65 Medium'), url('HelveticaNeue-Medium.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 67 Medium Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 67 Medium Condensed Oblique'), url('HelveticaNeue-MediumCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Bold';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Bold'), url('HelveticaNeueBold.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Condensed Bold';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Condensed Bold'), url('HelveticaNeueCondensedBold.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 75 Bold Outline';
font-style: normal;
font-weight: normal;
src: local('Helvetica 75 Bold Outline'), url('helvetica-75-bold-outline-587ebe00b76ba.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Bold Italic'), url('HelveticaNeueBoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 97 Black Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica 97 Black Condensed'), url('HelveticaNeue-BlackCond.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue Condensed Black';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Condensed Black'), url('HelveticaNeueCondensedBlack.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 97 Black Condensed Oblique';
font-style: normal;
font-weight: normal;
src: local('Helvetica 97 Black Condensed Oblique'), url('HelveticaNeue-BlackCondObl.woff') format('woff');
}


@font-face {
font-family: 'Helvetica 96 Black Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica 96 Black Italic'), url('HelveticaBlkIt.woff') format('woff');
}